import { Container, Nav, Offcanvas, Navbar } from "react-bootstrap";
import RishuBtn from "../RishuBtn/RishuBtn";
import './TopNavBar.css';
import logo from '../../imgs/logo.png';
import { useState } from "react";


function TopNavBar({onEnrollClick}) {
    const expandScreen = "lg";

    const closeNavbar = ()=>{
        onEnrollClick();
    }

    return (
        <>
            <Navbar collapseOnSelect key={expandScreen} expand={expandScreen} fixed="top">
                <Container className="topnav">
                    <Navbar.Brand href="#home">
                        <img src={logo} className="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='navbar' />
                    <Navbar.Offcanvas
                        id="navbar"
                        aria-labelledby="navbarOffcanvas"
                        placement="start"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id='navbarOffcanvas'>
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-center flex-grow-1">
                                <Nav.Link href="#home">Home</Nav.Link>
                                <Nav.Link href="#whoweare">Who We Are</Nav.Link>
                                <Nav.Link href="#course">Courses</Nav.Link>
                                <Nav.Link href="#students">Our Students</Nav.Link>
                                <Nav.Link href="#contact">Contact Us</Nav.Link>
                            </Nav>
                            <RishuBtn text="GET ENROLL" className='float-end d-lg-block' onClick={closeNavbar}/>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

export default TopNavBar;