import './RishuBtn.css';

function RishuBtn({text, onClick, className}){

    const classStr = 'dynamicBtn '+ className;

    return(
        <>
            <div className={classStr} onClick={onClick}>
                <span>{text}</span>
            </div>
        </>
    )

}


export default RishuBtn;