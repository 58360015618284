import { useEffect, useRef, useState } from 'react';
import './RishuLoader.css';
function RishuLoader({complete, children}){

    const [progress, setProgress] = useState(0);
    const [destroy, setDestroy] = useState(false);
    const delay = complete ? 10 : 50;
    
    useEffect(()=>{
        if (progress < 100) {
            const timer = setInterval(() => {
                setProgress((progress) => progress + 1);
            }, delay);
      
            // Cleanup the timer when component unmounts or count reaches 0
            return () => clearInterval(timer);
        }else{
            setTimeout(()=>{
                setDestroy(true);
            }, 500)
        }
    }, [progress])

    return(
        <>
            {
                destroy ?
                children :
                <div className='loadingWrapper'>
                    <h1>{progress}%</h1>
                    <span className="loader" style={{width: progress+"%"}}></span>
                </div>
            }
        </>
    )
}

export default RishuLoader;