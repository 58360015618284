// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCGLhbLuxcENOFUBXmwTxsgYEYyRb75yRU",
  authDomain: "rishutech-ed0a9.firebaseapp.com",
  projectId: "rishutech-ed0a9",
  storageBucket: "rishutech-ed0a9.appspot.com",
  messagingSenderId: "720704596271",
  appId: "1:720704596271:web:ce9bee1b5dcbb5545d19da"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);