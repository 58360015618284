
import { Col, Container, Row, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import TopNavBar from '../../components/TopNavBar/TopNavBar';
import './Home.css';
import Mainhead from '../../components/Mainhead/Mainhead';
import react from '../../imgs/productImg/react.png';
import node from '../../imgs/productImg/nodejs.png';
import angular from '../../imgs/productImg/angular.png'
import mongo from '../../imgs/productImg/db.png'
import next from '../../imgs/next.svg';
import fullstack from '../../imgs/fullstack.png';
import python from '../../imgs/python.webp';
import android from '../../imgs/android.png';
import ios from '../../imgs/ios.webp';
import ai from '../../imgs/ai.png'
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";
import { db } from "../../fbConfig";
import { collection, addDoc, getDocs, query, orderBy } from "firebase/firestore";
import Slider from "react-slick";
import RishuLoader from '../../components/RishuLoader/RishuLoader';

const enrolInitData = {
    name: '',
    email: '',
    mobile: '',
    course: '',
    createdAt: ''
}

function Home() {

    const whoweareText = "Welcome to RISHU.TECH, where innovation meets education! Established with the vision to empower the next generation of tech leaders, we are a premier institution dedicated to shaping the future of technology through cutting-edge research, hands-on learning, and a forward-thinking curriculum . At RISHU.TECH we blend academic rigor with real-world applications, offering programs in emerging fields like Artificial Intelligence, REACT.JS , NODE. JS , etc. Join us on our journey to create, innovate, and lead the future of technology.";

    const settings = {
        dots: true,
        speed: 500,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const imgCollection = {
        react,
        node,
        mongo,
        next,
        angular,
        fullstack,
        python,
        android,
        ios,
        ai
    }

    const [show, setShow] = useState(false);
    const [enrollData, setEnrollData] = useState(enrolInitData);
    const [showModal, setShowModal] = useState(false);
    const [courses, setCourses] = useState([]);

    const onSubmit = async () => {
        const mobilePattern = /^[0]?[789]\d{9}$/;
        if (enrollData.name == '') {
            alert("Please Enter Your Name");
            return;
        }
        if (!mobilePattern.test(enrollData.mobile)) {
            alert("Please Enter Your Mobile Number");
            return;
        }

        enrollData.createdAt = new Date();

        setShowModal(false);

        const enrolmentQueriesRef = collection(db, "enrollmentQueries");
        await addDoc(enrolmentQueriesRef, enrollData);

        setShow(enrollData.name)
        setEnrollData(enrolInitData);
    };

    const onModalOpen = () => {
        setEnrollData(enrolInitData);
        setShowModal(true);
    }

    const onModalClose = () => setShowModal(false);

    const onInputChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        const old = { ...enrollData };
        old[key] = value;
        setEnrollData(old);
    }

    const openEnqueryModal = (courseId) => {
        const data = { ...enrollData };
        data.course = courseId;
        setEnrollData(data)
        setShowModal(true);
    }

    const fetchAllCaurses = async () => {
        const collectionRef = collection(db, "courses");
        const q = query(collectionRef, orderBy("priority"));
        const snapshot = await getDocs(q);
        const allCourses = snapshot.docs.map((course) => ({ ...course.data(), id: course.id }));
        setCourses(allCourses);
    }

    useEffect(() => {
        fetchAllCaurses();
    }, []);

    return (
        <>
            <RishuLoader complete={courses.length}>
                    <section className='topSection' id="home">
                        <TopNavBar onEnrollClick={onModalOpen} />
                        <Mainhead />
                    </section>
                    <section className='whoweare' id="whoweare">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    {/* <h1 className='imagehead'>
                                    WHO WE ARE
                                    <div className='underline'></div>
                                </h1> */}
                                    <p className='introimge'>At RISHU.TECH </p>
                                    <p className='introimg'>{whoweareText}</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className='thirdsection' id="course">
                        <div className='coursename'>OUR COURSES</div>
                        <Container className='mt-4'>
                            <Row>
                                <Col>
                                    {courses.length &&
                                        <Slider {...settings}>
                                            {courses.map((course) => {
                                                return (
                                                    <div className='p-3' key={course.id}>
                                                        <div className='myCard'>
                                                            <img src={imgCollection[course.icon]} className='cardImg' alt={course.icon}/>
                                                            <h3>{course.name}</h3>
                                                            <div className='underline'></div>
                                                            <ul>
                                                                {
                                                                    course.content.map((c, i) => <li key={course.id + "-" + i}>{c}</li>)
                                                                }
                                                            </ul>
                                                            <button className='chooseBtn' onClick={() => openEnqueryModal(course.id)}>Enroll Now</button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='students'>

                    </section>
                    <footer id='contact'>
                        <Container>
                            <Row className='mb-5'>
                                <Col md={6}>
                                    <h3>CONNECT WITH US :</h3>
                                    <ul className='footerInfo'>
                                        <li>RISHU.TECH</li>
                                        <li>Address : B-356, GD Colony, Mayur Vihar Phase 3, Delhi</li>
                                        <li>Mob : 8860033237</li>
                                        <li>Email : mukulsoftwap@gmail.com</li>
                                    </ul>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='underline'></div>
                                    <p className='copyright'>Copyright © 2024 Rishu Tech. We Build Talent.</p>
                                </Col>
                            </Row>
                        </Container>
                    </footer>

                    <Modal show={showModal} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>ENROLL YOURSELF WITH <span className="rishucolor">RISHU.TECH</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Candidate Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="xyz"
                                        value={enrollData.name}
                                        onChange={onInputChange}
                                        name="name" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                        name="email"
                                        onChange={onInputChange}
                                        value={enrollData.email} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Contact Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="9678XXXXXX"
                                        name="mobile"
                                        onChange={onInputChange}
                                        value={enrollData.mobile} />
                                </Form.Group>

                                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Mention Course</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="html, react.js, C++ ...."
                                autoFocus/>
                            </Form.Group> */}
                                <Form.Select value={enrollData.course} name="course" onChange={onInputChange}>
                                    <option>Select Course</option>
                                    {courses.map((course) => <option value={course.id} key={course.id}>{course.name}</option>)}

                                </Form.Select>

                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={onModalClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={onSubmit}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <ToastContainer>
                        <Toast
                            bg={'success'}
                            key={'idx'}
                            onClose={() => setShow(false)} show={show} delay={400000} autohide
                        >
                            <Toast.Header>
                                <strong className="me-auto">Query Sent</strong>
                                <small>Success</small>
                            </Toast.Header>
                            <Toast.Body>
                                <h4 className="toastHeading">Hi {show},</h4>
                                <p className="toastMessage">Thanks for showing intrest, our team will contact you soon.</p>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>
            </RishuLoader>
        </>
    )
}
export default Home;